import SmoothScroll from 'smooth-scroll';

export class SmoothScrollClass {

    public smoothScrollFunction() {
        new SmoothScroll('a[href*="#"]', {
            speed: 1000,
            easing: 'easeInOutQuad',
        });
    }

}