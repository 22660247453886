import { WaterfallAnimation } from './waterfallAnimation'
import { SmoothScrollClass } from './smoothScroll'
import { setupBinaryListener } from './binary'
import { CookiesPolicy } from './cookiesPolicy'

function init() {
  const waterfallAnimation = new WaterfallAnimation('waterfall-animation-right', 'right', 1);
  waterfallAnimation.waterfallAnimation()

  const waterfallAnimation2 = new WaterfallAnimation('waterfall-animation-left', 'left', 1);
  waterfallAnimation2.waterfallAnimation()

  const homePageWave = new WaterfallAnimation('home-page-wave-parallax', 'left', 2);
  homePageWave.waterfallAnimation()

  const smoothScroll = new SmoothScrollClass();
  smoothScroll.smoothScrollFunction()

  setupBinaryListener()

  const cookiesPolicy = new CookiesPolicy()
}

window.addEventListener('load', () => {
  init()
})
