export class WaterfallAnimation {
    private forClass: string;
    private direction: "up" | "down" | "left" | "right" | undefined;
    private scale: number;

    constructor(forClass: string, direction: "up" | "down" | "left" | "right" | undefined, scale: number) {
        this.forClass = forClass
        this.direction = direction
        this.scale = scale
      }

    public waterfallAnimation() {
        const waterfallImage = document.getElementsByClassName(this.forClass)

        /*new SimpleParallax(Array.from(waterfallImage), {
            orientation: this.direction,
            overflow: true,
            scale: this.scale
        });*/


    }

}