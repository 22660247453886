
function pageToBinary() {
    document.querySelectorAll("*").forEach((e) => {
        const text = e.innerHTML
        if (!text.includes("<")) {
            e.textContent = text.split("").map((l) => {
                return l.charCodeAt(0).toString(2) + " "
            }).join("");
        }
    })
}

export function setupBinaryListener() {
    const name = document.getElementById("name")
    if (name != null) {
        let times = 0
        name.onclick = () => {
            times += 1

            if (times === 5) {
                pageToBinary()
            }
        }
    }
}